export const PASSIVE = { passive: true }

export const PREVENT_OVERFLOW_PADDING = 5

export const ROUND_ARROW_INNER_HTML =
  '<svg viewBox="0 0 18 7" xmlns="http://www.w3.org/2000/svg"><path d="M0 7s2.021-.015 5.253-4.218C6.584 1.051 7.797.007 9 0c1.203-.007 2.416 1.035 3.761 2.782C16.012 7.005 18 7 18 7H0z"/></svg>'

export const IOS_CLASS = `__NAMESPACE_PREFIX__-iOS`
export const POPPER_CLASS = `__NAMESPACE_PREFIX__-popper`
export const TOOLTIP_CLASS = `__NAMESPACE_PREFIX__-tooltip`
export const CONTENT_CLASS = `__NAMESPACE_PREFIX__-content`
export const BACKDROP_CLASS = `__NAMESPACE_PREFIX__-backdrop`
export const ARROW_CLASS = `__NAMESPACE_PREFIX__-arrow`
export const SVG_ARROW_CLASS = `__NAMESPACE_PREFIX__-svg-arrow`

export const POPPER_SELECTOR = `.${POPPER_CLASS}`
export const TOOLTIP_SELECTOR = `.${TOOLTIP_CLASS}`
export const CONTENT_SELECTOR = `.${CONTENT_CLASS}`
export const BACKDROP_SELECTOR = `.${BACKDROP_CLASS}`
export const ARROW_SELECTOR = `.${ARROW_CLASS}`
export const SVG_ARROW_SELECTOR = `.${SVG_ARROW_CLASS}`
